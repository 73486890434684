import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import Router from 'next/router'
import getConfig from 'next/config'
import { destroyCookie } from 'nookies'
import { makeUseAxios } from 'axios-hooks'
import { GetServerSidePropsContext } from 'next'

const { publicRuntimeConfig } = getConfig()
const timeout = 70000
const baseURL = typeof window === 'undefined' ? publicRuntimeConfig.apiUrl : '/api'

const api = axios.create({ timeout, baseURL })

declare module 'axios' {
  interface AxiosRequestConfig {
    ctx?: GetServerSidePropsContext
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
api.get = async <T = any, R = AxiosResponse<T>>(url: string, config: AxiosRequestConfig) => {
  try {
    const response = await api.request<T, R>({ method: 'GET', timeout, baseURL, url, ...config })
    return response
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      return Promise.reject(error)
    }

    // Handle unauthorized error by redirecting to login and clearing any cookies
    if (error.response && error.response.status === 401) {
      if (process.browser) {
        destroyCookie(null, 'user')
        Router.push('/login')
      }
      if (config && config.ctx && config.ctx.res) {
        config.ctx.res.statusCode = 302
        config.ctx.res.setHeader('Location', '/login')
        config.ctx.res.setHeader('Set-Cookie', `user=; path=/`)
      }
      return { data: {} } as unknown as R
    }

    // Handle not found error by redirecting to 404 nextjs page
    if (error.response?.status === 404) {
      if (process.browser) {
        Router.push('/404')
      }
      if (config && config.ctx && config.ctx.res) {
        config.ctx.res.statusCode = 302
        config.ctx.res.setHeader('Location', '/404')
      }
      return { data: {} } as unknown as R
    }

    return Promise.reject(error)
  }
}

export default api
export const useApi = makeUseAxios({ axios: api, defaultOptions: { ssr: false } })
