import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://30718f72e81349e7970fa43708cde1b9@o554923.ingest.sentry.io/5690835',
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  enabled: !['development', 'testing'].includes(`${publicRuntimeConfig.env}`),
  autoSessionTracking: true,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
