import { createTheme, responsiveFontSizes, lighten } from '@mui/material/styles'

const theme = createTheme({
  spacing: (factor) => `${0.25 * factor}rem`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'IBM Plex Sans,sans-serif',
  },
  palette: {
    primary: {
      main: '#ff6c2f',
    },
    secondary: { main: '#74c4d7' },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          border: `1px solid #B0BEC5`,
        },
      },
    },
    MuiExpansionPanel: {
      styleOverrides: {
        root: {
          '& .MuiExpansionPanel-expanded': {
            margin: '1px 0px 1px 0px',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          top: 0,
          position: 'sticky',
          background: 'white',
        },
      },
    },
  },
})

export default function Theme() {
  return responsiveFontSizes(
    createTheme(theme, {
      palette: {
        action: {
          hover: lighten(theme.palette.primary.main, 0.95),
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
              fontWeight: 600,
            },
            containedPrimary: {
              color: '#fff',
            },
            outlinedPrimary: {
              color: '#000',
              borderColor: 'rgba(0, 0, 0, 0.25)',
              '&:hover': {
                backgroundColor: lighten(theme.palette.primary.main, 0.7),
              },
            },
          },
        },
      },
    }),
  )
}
