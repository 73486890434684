import React from 'react'
import App from 'next/app'
import Router from 'next/router'
import getConfig from 'next/config'
import { CssBaseline } from '@mui/material'
import { parseCookies, destroyCookie } from 'nookies'
import { CacheProvider } from '@emotion/react'
import { ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import TagManager from 'react-gtm-module'
import NProgress from 'nprogress'
import '../public/nprogress.css'
import api from 'utils/api'
import * as Sentry from '@sentry/nextjs'
import AppContext from 'contexts/App'
import Script from 'next/script'
import createEmotionCache from 'lib/createEmotionCache'
import getTheme from '../theme'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const clientSideEmotionCache = createEmotionCache()

export default class ScenicAmp extends App {
  constructor(props) {
    super(props)
    this.state = {
      name: 'Scenic AMP',
      locale: 'en',
      user: this.props.user,
    }
  }

  static async getInitialProps(appContext) {
    const appProps = await App.getInitialProps(appContext)
    if (process.browser) return { ...appProps }

    const { res, pathname } = appContext.ctx

    try {
      const cookie = parseCookies(appContext.ctx)
      const user = JSON.parse(cookie.user)
      api.defaults.headers.common.authtoken = user.token
      appProps.user = user
      Sentry.setUser({ email: user.email, username: user.displayName, id: user._id })
    } catch (error) {
      // No user Cookie
    }

    // If the response has already been sent, don't try to redirect.
    if (!res.writableEnded) {
      if (!appProps.user && pathname !== '/login') {
        res.writeHead(302, { Location: '/login' })
        res.end()
      }

      if (appProps.user && pathname === '/login') {
        res.writeHead(302, { Location: '/' })
        res.end()
      }
    }

    return { ...appProps }
  }

  logout = () => {
    destroyCookie(null, 'user')
    window.location = '/login'
  }

  componentDidMount() {
    if (this.props.user) {
      api.defaults.headers.common.authtoken = this.props.user.token
    }

    const { publicRuntimeConfig } = getConfig()
    if (
      publicRuntimeConfig.env !== 'development' &&
      publicRuntimeConfig.gtmId &&
      publicRuntimeConfig.gtmId !== ''
    ) {
      TagManager.initialize({ gtmId: publicRuntimeConfig.gtmId })
    }
  }

  render() {
    const { Component, pageProps, emotionCache = clientSideEmotionCache, err } = this.props
    const { publicRuntimeConfig } = getConfig()

    return (
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={getTheme()}>
          <CssBaseline />
          {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
          <AppContext.Provider value={[{ ...this.state, logout: this.logout }, this.setState]}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              maxSnack={3}>
              {publicRuntimeConfig.env !== 'development' ? (
                <>
                  <Script
                    src="https://www.googletagmanager.com/gtag/js?id=UA-133177471-2"
                    strategy="afterInteractive"
                  />
                  <Script id="google-analytics" strategy="afterInteractive">
                    {`
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', 'UA-133177471-2');
                    `}
                  </Script>
                </>
              ) : null}
              <Component {...pageProps} err={err} />
            </SnackbarProvider>
          </AppContext.Provider>
        </ThemeProvider>
      </CacheProvider>
    )
  }
}
